import { formatPhoneNumberIntl } from 'react-phone-number-input'
import map from 'lodash/map'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'

import { getInvoiceIds, buildSegmentSubscriptionData } from 'helpers/account'
import { viewOrDownloadFile } from 'helpers/file'
import { removeAlipayParams } from 'helpers/registrations'
import * as cardInfoActionType from 'constants/action_types/card_info'
import * as type from 'constants/action_types/accounts'
import {
  PRODUCT_FOR_SEGMENT,
  sendCtaClickEvent,
  sendSegmentFileDownloadedEvent
} from 'helpers/segment'
import { stripURLParams } from 'helpers/url'
import {
  SET_GLOBAL_MODAL,
  LOAD_GLOBAL_DATA,
  SET_REGISTRATION_INFO,
  SET_SEAT_DATA,
  SET_SUBSCRIPTION_INFO,
  PENDING_REGISTRATION_ACTION,
  COMPLETED_REGISTRATION_ACTION
} from 'constants/action_types'
import { REMOVE_BANNER } from 'constants/banners'
import Api from 'services/api'

import {
  sendGoogleEvent,
  getGAMembershipPlanType
} from 'services/google_data_layer'
import { executeRecaptcha } from 'services/google_recaptcha'
import { sendSegmentTrackAndIdentifyEvents } from 'services/segment'

import { createNotification } from '../notifications'
import { validateInputField } from '../../helpers/forms'
import { getShowState } from '../../helpers/core'

import { LanguageDetector } from '../../helpers/localization/detector'
import { NEW_TEAM_MODAL_NAME } from '../../constants/account/team_management'

export const loadAccountData = () => async dispatch => {
  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'page'
  })

  const res = await Api.loadAccountData()

  if (res.ok) {
    dispatch({
      type: type.COMPLETED_LOAD_ACCOUNT,
      payload: res.data
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Account Error'
      })
    )
  }
}

export const accountHandleInputChange = (prop, value) => dispatch => {
  dispatch({
    type: type.ACCOUNT_INPUT_FIELD_UPDATE,
    payload: {
      prop,
      value
    }
  })
}

export const accountHandleDropdownChange = (prop, value) => dispatch => {
  dispatch({
    type: type.ACCOUNT_DROPDOWN_FIELD_UPDATE,
    payload: {
      prop,
      value
    }
  })
}

export const validateAccountProfileInput = (field, input) => dispatch => {
  const validation = validateInputField(field, input)
  dispatch({
    type: type.ADD_ACCOUNT_PROFILE_VALIDATION_ERROR,
    payload: {
      field,
      message: validation.message
    }
  })
}

export const updateProfile = () => async (dispatch, getState) => {
  const {
    userInfo: {
      email,
      firstName,
      lastName,
      isAmazonSeller,
      photo,
      sellerType,
      annualRevenue
    },
    saveProfile: { isLoading, completed }
  } = getState().account

  // Disable action when it's running
  if (isLoading || completed) {
    return
  }

  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'saveProfile'
  })

  const res = await Api.updateProfile({
    first_name: firstName,
    last_name: lastName,
    email,
    is_amazon_seller: isAmazonSeller,
    seller_type: sellerType,
    annual_revenue: annualRevenue,
    is_selling: !!annualRevenue
  })

  if (res.ok) {
    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: 'Account Updated'
      })
    )

    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'saveProfile'
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_ACCOUNT_ACTION,
        payload: 'saveProfile'
      })
    }, 5000)

    const fullName = `${firstName} ${lastName}`
    const segmentEventData = {
      avatar: photo,
      email,
      firstName,
      lastName,
      name: fullName,
      amazonSeller: isAmazonSeller,
      product: PRODUCT_FOR_SEGMENT
    }

    const { user } = getState().globalData

    sendSegmentTrackAndIdentifyEvents(
      'Account Information Updated',
      segmentEventData,
      user.id,
      { email }
    )
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Account Change Error:'
      })
    )

    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'saveProfile'
    })
  }
}

export const updatePassword = () => async (dispatch, getState) => {
  const {
    userInfo: {
      oldPassword,
      newPassword,
      firstName,
      lastName,
      photo,
      email,
      isAmazonSeller
    },
    savePassword: { isLoading, completed }
  } = getState().account

  // Disable action when it's running
  if (isLoading || completed) {
    return
  }

  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'savePassword'
  })

  const res = await Api.updatePassword({
    oldPassword,
    newPassword
  })

  if (res.ok) {
    dispatch({
      type: type.RESET_USER_INFO
    })

    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: 'Account Updated'
      })
    )

    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'savePassword'
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_ACCOUNT_ACTION,
        payload: 'savePassword'
      })
    }, 5000)

    const fullName = `${firstName} ${lastName}`
    const segmentEventData = {
      avatar: photo,
      email,
      firstName,
      lastName,
      name: fullName,
      amazonSeller: isAmazonSeller,
      product: PRODUCT_FOR_SEGMENT
    }

    const { user } = getState().globalData

    sendSegmentTrackAndIdentifyEvents(
      'Account Information Updated',
      segmentEventData,
      user.id,
      { email }
    )
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Account Change Error:'
      })
    )

    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'savePassword'
    })
  }
}

export const toggleNewTeamModal = () => (dispatch, getState) => {
  const current = getState().globalData.modal
  dispatch({
    type: SET_GLOBAL_MODAL,
    payload: current === NEW_TEAM_MODAL_NAME ? null : NEW_TEAM_MODAL_NAME
  })
}

export const toggleAuthySignUpModal = () => dispatch => {
  dispatch({
    type: type.TOGGLE_AUTHY_SIGNUP_MODAL
  })
}

export const handlePhoneNumberInput = value => dispatch => {
  dispatch({
    type: type.AUTHY_INPUT_FIELD_UPDATE,
    payload: {
      prop: 'phoneNumber',
      value
    }
  })
}

export const handleAuthySubmit = () => async (dispatch, getState) => {
  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'authy'
  })

  const { email } = getState().account.userInfo
  const { phoneNumber } = getState().account.authy
  const internationalPhoneNumber = formatPhoneNumberIntl(phoneNumber)
  const phoneNumberSegments = internationalPhoneNumber.split(' ')
  const country_code = phoneNumberSegments[0].replace('+', '')
  const mainPhoneNumber = phoneNumberSegments
    .slice(1, phoneNumberSegments.length)
    .join('')
  const params = {
    phone_number: mainPhoneNumber,
    country_code,
    email
  }

  const res = await Api.registerAuthy(params)

  if (res.ok) {
    dispatch({
      type: type.TOGGLE_AUTHY_SIGNUP_MODAL,
      payload: false
    })

    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'authy'
    })

    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'authy'
    })

    dispatch({
      type: type.AUTHY_INPUT_FIELD_UPDATE,
      payload: {
        prop: 'hasAuthy',
        value: true
      }
    })

    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: 'Account:'
      })
    )
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Authy Error:'
      })
    )
    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'authy'
    })
  }
}

export const uploadAccountPhoto = selectedFile => async dispatch => {
  if (!selectedFile || !selectedFile.name) {
    return
  }

  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'uploadingPhoto'
  })

  const data = new FormData()
  data.append('file', selectedFile, selectedFile.name)

  const res = await Api.uploadAccountPhoto(data)

  if (res.ok) {
    const { photo } = res.data
    dispatch({
      type: type.ACCOUNT_PHOTO_UPLOADED,
      payload: photo
    })

    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: 'Photo Upload'
      })
    )

    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'uploadingPhoto'
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_ACCOUNT_ACTION,
        payload: 'uploadingPhoto'
      })
    }, 3000)
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Photo Upload'
      })
    )
    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'uploadingPhoto'
    })
  }
}

export const deleteAccountPhoto = () => async (dispatch, getState) => {
  const { account_photo } = getState().globalData.user

  if (!account_photo) return

  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'deletingPhoto'
  })

  const res = await Api.deleteAccountPhoto()

  if (res.ok) {
    dispatch({
      type: type.ACCOUNT_PHOTO_DELETED
    })

    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: 'Photo Delete'
      })
    )

    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'deletingPhoto'
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_ACCOUNT_ACTION,
        payload: 'deletingPhoto'
      })
    }, 3000)
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Photo Delete'
      })
    )
    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'deletingPhoto'
    })
  }
}

export const removeAuthy = () => async dispatch => {
  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'authy'
  })

  const res = await Api.deleteAuthy()

  if (res.ok) {
    dispatch({
      type: type.AUTHY_INPUT_FIELD_UPDATE,
      payload: {
        prop: 'hasAuthy',
        value: false
      }
    })

    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: 'Authy'
      })
    )

    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'authy'
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_ACCOUNT_ACTION,
        payload: 'authy'
      })
    }, 5000)
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Authy Error:'
      })
    )
    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'authy'
    })
  }
}

export const removeCreditCard = () => async dispatch => {
  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'creditCard'
  })

  const res = await Api.removeCard()

  if (res.ok) {
    const {
      message,
      data: { cardInfo }
    } = res

    dispatch({
      type: cardInfoActionType.SET_CARD_INFO,
      payload: cardInfo
    })

    dispatch({
      type: SET_GLOBAL_MODAL
    })

    dispatch(
      createNotification({
        message,
        level: 'success',
        title: 'Credit Card Successfully Removed'
      })
    )

    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'creditCard'
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_ACCOUNT_ACTION,
        payload: 'creditCard'
      })
    }, 3000)
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Error Removing Credit Card'
      })
    )
    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'creditCard'
    })
  }
}

export const updateCreditCard = ({
  source,
  token,
  isAlipay
}) => async dispatch => {
  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'stripe'
  })

  const stripe_customer_token = token ? token.id : source
  const languageDetector = new LanguageDetector()
  const language = languageDetector.detect()
  const recaptchaToken = await executeRecaptcha('update_payment')

  if (isAlipay) {
    dispatch({
      type: PENDING_REGISTRATION_ACTION,
      payload: 'alipay'
    })

    dispatch({ type: type.BIND_ALIPAY_LOADING, payload: true })
  }

  const res = await Api.updateCard({
    stripe_customer_token,
    language,
    recaptchaToken
  })

  if (res.ok) {
    const {
      message,
      data: { cardInfo, membershipInfo }
    } = res

    dispatch({
      type: cardInfoActionType.SET_CARD_INFO,
      payload: cardInfo
    })

    dispatch({
      type: LOAD_GLOBAL_DATA,
      payload: {
        membershipInfo
      }
    })
    dispatch(
      createNotification({
        message,
        level: 'success',
        title: 'Payment Information:'
      })
    )

    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'stripe'
    })

    dispatch({
      type: SET_GLOBAL_MODAL
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Payment Error:'
      })
    )
    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'stripe'
    })
  }

  dispatch({
    type: SET_REGISTRATION_INFO,
    payload: { paymentMethod: null }
  })

  if (source) {
    // Need to clear Alipay's parameters from the url string
    window.history.replaceState(
      {},
      document.title,
      removeAlipayParams(window.location.href)
    )
  }

  if (isAlipay) {
    dispatch({
      type: COMPLETED_REGISTRATION_ACTION,
      payload: 'alipay'
    })

    dispatch({ type: type.BIND_ALIPAY_LOADING, payload: false })

    window.localStorage.removeItem('ali_secret')
  }
}

/**
 *
 * Check All Invoices Actions
 *
 */

export const toggleCheckInvoice = invoice => dispatch => {
  dispatch({
    type: type.TOGGLE_CHECK_INVOICE,
    payload: invoice
  })
}

export const toggleCheckAllInvoices = () => dispatch => {
  dispatch({
    type: type.TOGGLE_CHECK_ALL_INVOICES
  })
}

export const viewInvoice = ({ id }) => async dispatch => {
  dispatch({
    type: type.SET_ACTIVE_INVOICE,
    payload: id
  })

  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'viewInvoice'
  })

  const res = await Api.downloadInvoice({
    id
  })

  if (res.ok) {
    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'viewInvoice'
    })
    setTimeout(() => {
      dispatch({
        type: type.RESET_ACCOUNT_ACTION,
        payload: 'viewInvoice'
      })
    }, 5000)

    viewOrDownloadFile(res.data, 'application/pdf')
  } else {
    dispatch(
      createNotification({
        level: 'error',
        message: res.error,
        title: 'Billing Error:'
      })
    )
    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'viewInvoice'
    })
  }
}

export const downloadInvoice = ({ id, date }) => async dispatch => {
  dispatch({
    type: type.SET_ACTIVE_INVOICE,
    payload: id
  })

  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'downloadInvoice'
  })

  const res = await Api.downloadInvoice({
    id
  })

  if (res.ok) {
    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'downloadInvoice'
    })
    setTimeout(() => {
      dispatch({
        type: type.RESET_ACCOUNT_ACTION,
        payload: 'downloadInvoice'
      })
    }, 5000)

    const fileName = `Jungle Scout Invoice - ${date}.pdf`
    viewOrDownloadFile(res.data, 'application/pdf', fileName)
    sendSegmentFileDownloadedEvent('Account - Billing', 'Invoice.pdf')
  } else {
    dispatch(
      createNotification({
        level: 'error',
        message: res.error,
        title: 'Billing Error:'
      })
    )
    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'downloadInvoice'
    })
  }
}

export const downloadMultipleInvoices = () => async (dispatch, getState) => {
  dispatch({
    type: type.SET_ACTIVE_INVOICE,
    payload: null
  })

  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'downloadInvoice'
  })

  const ids = map(
    filter(getState().account.billing.invoices, {
      checked: true
    }),
    'id'
  )

  if (ids.length === 0) {
    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION
    })
    return
  }

  const res = await Api.downloadMultipleInvoices({
    ids
  })

  if (res.ok) {
    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'downloadInvoice'
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_ACCOUNT_ACTION,
        payload: 'downloadInvoice'
      })
    }, 5000)

    viewOrDownloadFile(res.data, 'application/zip', `Jungle Scout Invoices.zip`)
  } else {
    dispatch(
      createNotification({
        level: 'error',
        message: res.error,
        title: 'Billing Error:'
      })
    )

    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'downloadInvoice'
    })
  }

  dispatch({
    type: type.RESET_CHECKED_INVOICES
  })
}

export const emailInvoices = id => async (dispatch, getState) => {
  dispatch({
    type: type.SET_ACTIVE_INVOICE,
    payload: id
  })

  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'emailInvoice'
  })

  const ids = getInvoiceIds(getState().account.billing.invoices, id)
  const res = await Api.emailInvoices({
    ids
  })

  if (res.ok) {
    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: 'Email Invoice Success'
      })
    )

    dispatch({
      type: type.RESET_CHECKED_INVOICES
    })

    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'emailInvoice'
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_ACCOUNT_ACTION,
        payload: 'emailInvoice'
      })
    }, 5000)
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Email Invoice Failed'
      })
    )

    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'emailInvoice'
    })
  }
}

export const invoiceHandleInputChange = (prop, value) => dispatch => {
  if (prop === 'showEmail') {
    value = getShowState(!value, value)
  }

  dispatch({
    type: type.INVOICE_INPUT_FIELD_UPDATE,
    payload: {
      prop,
      value
    }
  })
}

export const handleInvoiceSaveChanges = () => async (dispatch, getState) => {
  const {
    billing: { settings },
    userInfo: { email },
    saveAccount: { isLoading, completed }
  } = getState().account

  // Disable action when it's running
  if (isLoading || completed) {
    return
  }

  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'saveAccount'
  })

  const params = settings
  params.email = !!settings.showEmail && email

  const res = await Api.updateInvoiceInfo(params)

  if (res.ok) {
    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: 'Company Details:'
      })
    )

    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'saveAccount'
    })

    dispatch({
      type: type.COMPLETED_BILLING_ACTION,
      payload: res.data
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_ACCOUNT_ACTION,
        payload: 'saveAccount'
      })

      dispatch({
        type: SET_GLOBAL_MODAL
      })
    }, 3000)

    const { user } = getState().globalData
    const getAddress = () => {
      const result = []
      if (params.line1) result.push(params.line1)
      if (params.line2) result.push(params.line2)
      return result.join(' ')
    }

    const address = getAddress()

    const segmentEventData = {
      company: params.business,
      address,
      city: params.city,
      state: params.state,
      zipcode: params.zip,
      country: params.country,
      VAT: params.vat,
      additionalInformation: params.additional_info
    }
    sendSegmentTrackAndIdentifyEvents(
      'Company Details Added',
      segmentEventData,
      user.id,
      { email }
    )
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Account Error:'
      })
    )
    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'saveAccount'
    })
  }
}

export const loadInvoices = page => async dispatch => {
  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'invoices'
  })

  const res = await Api.getInvoices({ page })

  if (res.ok) {
    dispatch({
      type: type.COMPLETED_BILLING_ACTION,
      payload: res.data
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Account Error:'
      })
    )
  }

  dispatch({
    type: type.RESET_ACCOUNT_ACTION,
    payload: 'invoices'
  })
}

export const inviteUsers = ({ emails, teamIds }) => async (
  dispatch,
  getState
) => {
  if (isEmpty(emails)) {
    return
  }

  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'inviteUsers'
  })

  const res = await Api.inviteUsers({
    emails,
    team_ids: teamIds.join(',')
  })

  if (res.ok) {
    const {
      message,
      data: { membershipInfo, seats }
    } = res

    dispatch({
      type: SET_SUBSCRIPTION_INFO,
      payload: {
        membershipInfo
      }
    })

    dispatch({
      type: SET_SEAT_DATA,
      payload: seats
    })

    dispatch({
      type: type.ACCOUNT_INPUT_FIELD_UPDATE,
      payload: {
        prop: 'users',
        value: ''
      }
    })

    dispatch(
      createNotification({
        message,
        level: 'success',
        title: 'Invitation sent!'
      })
    )

    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'inviteUsers'
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_ACCOUNT_ACTION,
        payload: 'inviteUsers'
      })
    }, 5000)

    dispatch({
      type: SET_GLOBAL_MODAL,
      payload: null
    })

    const { user } = getState().globalData

    sendSegmentTrackAndIdentifyEvents(
      'User Invited',
      {
        tab: 'User & Team Management',
        inviteeEmail: emails,
        paidSeats: membershipInfo.user_limit,
        usedSeats: membershipInfo.active_users_count,
        planTypeID: membershipInfo.id,
        planDuration:
          membershipInfo.stripe_plan?.interval === 'year'
            ? 12
            : membershipInfo.stripe_plan?.interval_count,
        product: PRODUCT_FOR_SEGMENT,
        extension: user.has_extension_access
      },
      user.id,
      { email: user.email }
    )
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Account Error:'
      })
    )
    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'inviteUsers'
    })
  }
}

export const resendInvite = email => async dispatch => {
  if (isEmpty(email)) return

  const res = await Api.resendInvite({
    email
  })

  if (res.ok) {
    const { message } = res
    dispatch(
      createNotification({
        message,
        level: 'success',
        title: 'Invitation sent!'
      })
    )
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Account Error:'
      })
    )
  }
}

export const toggleRevokeUserConfirmModal = email => dispatch => {
  dispatch({
    type: type.TOGGLE_REVOKE_USER_CONFIRM_MODAL,
    payload: email
  })
}

export const revokeUser = () => async (dispatch, getState) => {
  const { email } = getState().account.revokeUser

  if (!email) {
    return
  }

  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'revokeUser'
  })

  const res = await Api.revokeUser({
    email
  })

  if (res.ok) {
    const {
      message,
      data: { membershipInfo, seats }
    } = res

    dispatch({
      type: SET_SUBSCRIPTION_INFO,
      payload: {
        membershipInfo
      }
    })

    dispatch({
      type: SET_SEAT_DATA,
      payload: seats
    })

    dispatch({
      type: type.ACCOUNT_INPUT_FIELD_UPDATE,
      payload: {
        prop: 'users',
        value: ''
      }
    })

    dispatch(
      createNotification({
        message,
        level: 'success',
        title: 'Access Revoked:'
      })
    )

    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'revokeUser'
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_ACCOUNT_ACTION,
        payload: 'revokeUser'
      })

      dispatch({
        type: type.TOGGLE_REVOKE_USER_CONFIRM_MODAL,
        payload: null
      })
    }, 2000)
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Account Error:'
      })
    )

    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'revokeUser'
    })
  }
}

export const cancelMembership = (t, billingPeriodEnd = null) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'cancel'
  })

  const { answers } = getState().account.churnFunnel

  const res = await Api.cancelMembership({
    answers
  })

  if (res.ok) {
    const {
      data: { membershipInfo, billing, stripeSubscriptionInfo }
    } = res

    dispatch({
      type: SET_SUBSCRIPTION_INFO,
      payload: {
        membershipInfo,
        billing,
        stripeSubscriptionInfo
      }
    })

    dispatch({
      type: SET_GLOBAL_MODAL
    })
    const message = t(
      'generic:CancelPlanPeriodEndNotificationSuccess',
      'Your plan has been cancelled successfully. You will have access until the end of your billing cycle'
    )
    const messageWithDate = `${message} ${billingPeriodEnd}.`
    dispatch(
      createNotification({
        message: billingPeriodEnd ? messageWithDate : `${message}.`,
        level: 'success',
        title: t(
          'generic:CancelPlanNotificationSuccessTitle',
          'Plan Cancelled Successfully'
        )
      })
    )

    const { user } = getState().globalData
    const { stripe_customer_id } = user

    sendGoogleEvent({
      event: 'cancellation',
      product: getGAMembershipPlanType(membershipInfo),
      reason: answers.label,
      customerId: stripe_customer_id,
      planValue: membershipInfo.price / 100
    })

    sendCtaClickEvent({
      destination: '/#/account/subscriptions',
      text: 'Confirm Cancellation',
      location: 'Cancel Confirm',
      pageURL: stripURLParams(window.location.href),
      extraProps: {
        option: 'Cancel at Period End'
      }
    })

    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'cancel'
    })

    setTimeout(() => {
      dispatch({
        type: type.RESET_ACCOUNT_ACTION,
        payload: 'cancel'
      })
    }, 5000)
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Plan Cancellation Failed'
      })
    )

    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'cancel'
    })
  }
}

export const reactivateMembership = () => async (dispatch, getState) => {
  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'reactivate'
  })

  const res = await Api.reactivateMembership()

  if (res.ok) {
    const { membershipInfo, billing } = res.data

    dispatch({
      type: SET_SUBSCRIPTION_INFO,
      payload: {
        membershipInfo,
        billing
      }
    })

    dispatch({
      type: SET_GLOBAL_MODAL,
      payload: 'ReactivatedMembership'
    })

    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'reactivate'
    })

    dispatch({
      type: REMOVE_BANNER,
      payload: 'reactivate-membership'
    })

    const { user } = getState().globalData
    const { stripe_customer_id } = user

    sendGoogleEvent({
      event: 'reactivate',
      product: getGAMembershipPlanType(membershipInfo),
      customerId: stripe_customer_id,
      conversionValue: membershipInfo.price / 100
    })

    const subscriptionData = buildSegmentSubscriptionData({
      membershipInfo,
      user
    })

    sendSegmentTrackAndIdentifyEvents(
      'Subscription Resumed',
      subscriptionData,
      user.id
    )

    setTimeout(() => {
      dispatch({
        type: type.RESET_ACCOUNT_ACTION,
        payload: 'reactivate'
      })
    }, 5000)
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Reactivate Membership Failed'
      })
    )

    dispatch({
      type: type.RESET_ACCOUNT_ACTION,
      payload: 'reactivate'
    })
  }
}

export const deleteMembership = t => async dispatch => {
  dispatch({
    type: type.PENDING_ACCOUNT_ACTION,
    payload: 'cancel'
  })

  const res = await Api.deleteMembership()

  if (res.ok) {
    dispatch(
      createNotification({
        message: t(
          'generic:CancelPlanImmediatelyNotificationSuccessMessage',
          'Your access JS Web App features has been removed and you will recieve refund within 3-5 business days.'
        ),
        level: 'success',
        title: t(
          'generic:CancelPlanNotificationSuccessTitle',
          'Plan Cancelled Successfully'
        )
      })
    )

    sendCtaClickEvent({
      destination: '/#/account/subscriptions',
      text: 'Confirm Cancellation',
      location: 'Cancel Confirm',
      pageURL: stripURLParams(window.location.href),
      extraProps: { option: 'Cancel Now' }
    })

    dispatch({
      type: type.COMPLETED_ACCOUNT_ACTION,
      payload: 'cancel'
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'System Error'
      })
    )
  }

  dispatch({
    type: type.RESET_ACCOUNT_ACTION,
    payload: 'cancel'
  })
}

export const unbindAlipay = () => async dispatch => {
  dispatch({ type: type.UNBIND_ALIPAY_LOADING, payload: true })
  const res = await Api.unbindAlipay()

  if (res.ok) {
    const {
      message,
      data: { cardInfo }
    } = res

    dispatch({
      type: type.UNBIND_ALIPAY_LOADING,
      payload: false
    })

    dispatch({
      type: SET_GLOBAL_MODAL
    })

    dispatch({
      type: cardInfoActionType.SET_CARD_INFO,
      payload: cardInfo
    })

    dispatch(
      createNotification({
        message,
        level: 'success',
        title: 'Account'
      })
    )
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Account Error'
      })
    )
  }
}
